<template>
  <v-container class="pa-0">
    <base-subheading>On Instagram</base-subheading>

    <v-row>
      <v-col
        v-for="(post, i) in posts"
        :key="i"
        cols="6"
        sm="4"
      >
        <base-card
          :href="post.href"
          color="grey lighten-2"
          tag="a"
          target="_blank"
        >
          <v-img
            v-if="post.src"
            :src="require(`@/assets/instagram/${post.src}`)"
            height="100%"
          />
        </base-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'Instagram',

    data: () => ({
      posts: [
        {
          src: 'instagram1.PNG',
          href: 'https://www.instagram.com/itsmahsamiri/',
        },
        {
          src: 'instagram2.PNG',
          href: 'https://www.instagram.com/itsmahsamiri/',
        },
        {
          src: 'instagram3.PNG',
          href: 'https://www.instagram.com/itsmahsamiri/',
        },
        {
          src: 'instagram4.PNG',
          href: 'https://www.instagram.com/itsmahsamiri/',
        },
        {
          src: 'instagram5.PNG',
          href: 'https://www.instagram.com/itsmahsamiri/',
        },
        {
          src: 'instagram6.PNG',
          href: 'https://www.instagram.com/itsmahsamiri/',
        },
      ],
    }),
  }
</script>
